import axios from "axios";
export class ConfigService {
  loadConfig(callback) {
    const p_url = window.location.origin + "/app_data/appsettings.json";
    axios
      .get(p_url)
      .then((response) => {
        if (response && response.data) {
          localStorage.setItem("AppConfig", JSON.stringify(response.data));
          return callback(response.data);
        } else {
          return callback({});
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.setItem("AppConfig", undefined);
        return callback({});
      });
  }
}