import Vue from "vue";
import { ConfigService } from "./services/config-service";

let obj = new ConfigService();
let AppConfig = {};
let App = {};
let i18n = {};
let router = {};


obj.loadConfig(async function (resAppConfig) {
  console.log("resAppConfig", resAppConfig)
  AppConfig = resAppConfig;
  import("./services/collaboration.service").then(resService => {
    import("./router").then(async (rouTer) => {
      const CurrentURLPath = window.location.pathname;
      if (CurrentURLPath && CurrentURLPath.split("/collaboration/").length > 1) {
        const Token = CurrentURLPath.split("/collaboration/")[1].split("/")[0];
        if (Token) {
          sessionStorage.setItem("token", Token)
          await resService.getBranchAndDepartment((resUserSettings) => {
            if (resUserSettings && resUserSettings.status == 200) {
              const languageCode = resUserSettings.data.languageCode;
              if (languageCode) {
                const defaultLang = languageCode.split("-")[0];
                sessionStorage.setItem("lang", defaultLang);
                sessionStorage.setItem("localeLang", languageCode);
              }
            }
          })
        }
      }

      resService.setLangCode(function () {
        console.log("setLangCode41111111", sessionStorage.getItem("languages"))
        import("./locale/i18n").then(async (i18nLoad) => {
          import("./App.vue").then(async (app) => {
            initVue(app.default, i18nLoad.default, rouTer.default);
          });
        });
      });

    });
  })
  sessionStorage.setItem("tenant", AppConfig.Tenant);
});

import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import "@/assets/css/style.css";
var VueCookie = require('vue-cookie');
import vuetify from "@/plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import { jwtInterceptor } from "./_helpers/jwt.interceptor";
import Notifications from "vue-notification";
import VueEditor  from "vue2-editor";
import VueLoaders from "vue-loaders";
import "vue-loaders/dist/vue-loaders.css";
import Swal from 'sweetalert2';
import {
  languageTranslationService,
} from "./services/observables";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueChatScroll from 'vue-chat-scroll'
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(VueMaterial);
Vue.use(VueCookie)
Vue.use(Notifications);
Vue.use(VueEditor);
Vue.use(VueLoaders);
Vue.use(Toast);
Vue.use(VueChatScroll)

const appVersion = require("../package.json").version;

try {
  sessionStorage.setItem("appVersion", appVersion);
} catch (err) {
  const Token = window.location.pathname.split("/collaboration/")[1].split("/")[0];
  let appsettings=window.location.origin + "/app_data/appsettings.json"
  axios
  .get(appsettings)
  .then((response) => {
    if (response && response.data) {
      const httpCommonNotificationUrlApi = axios.create({
        baseURL: response.data.UrlNotification,
        headers: {
          Authorization: "Bearer " + Token,
        },
      });
      httpCommonNotificationUrlApi.interceptors.request.use(
        (req) => {
          req.headers.Authorization = "Bearer " + Token;
          return req;
        },
        (error) => {
          console.log(`error ${error}`);
          return Promise.reject(error);
        }
      );
      httpCommonNotificationUrlApi.get("/v1/settings")
          .then((resUserSettings) => {
          let languageCode=''
            if (resUserSettings && resUserSettings.status == 200) {
              languageCode = resUserSettings.data.languageCode;
            }else{
              languageCode='en-UK'
            }
            var property = {
              en_UKTitle:'Alert!',
              de_DETitle:'Alarm!',
              fr_FRTitle:'Alerte!',
              en_UKMessageText:'It seems you are running the application in a private browser window. If your browser supports, please off 3rd-party cookies to run the application properly.',
              de_DEMessageText:'Es scheint, dass Sie die Anwendung in einem privaten Browserfenster ausführen. Wenn Ihr Browser dies unterstützt, deaktivieren Sie bitte Cookies von Drittanbietern, um die Anwendung ordnungsgemäß auszuführen.',
              fr_FRMessageText:`Il semble que vous exécutiez l'application dans une fenêtre de navigateur privée. Si votre navigateur le prend en charge, veuillez désactiver les cookies tiers pour exécuter l'application correctement.`
            };

            Swal.fire({
              title: property[languageCode.replace("-","_")+'Title'],
              text:property[languageCode.replace("-","_")+'MessageText'],
              type: 'warning',
              confirmButtonColor: '#f8a31e',
              confirmButtonText: 'Ok',
              buttonsStyling: true,
              allowOutsideClick: false
            })
      })
    }
  })
 
}

Vue.filter("uppercase", function (value) {
  if (value) {
    return value.toUpperCase();
  }
});

Vue.filter("get2first", function (value) {
  if (value) {
    return (value || "").slice(0, 2);
  }
});

function initVue(app, i18N, routeR) {
  jwtInterceptor();
  App = app;
  i18n = i18N;
  router = routeR;


  Vue.filter("translate", function (fallbackStr, str) {
    return i18n.t && i18n.te
      ? i18n.te(str)
        ? i18n.t(str)
        : fallbackStr
      : fallbackStr
        ? fallbackStr
        : str;
  });

  new Vue({
    router,
    i18n,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");

  if (sessionStorage.getItem("lang")) {
    i18n.locale = sessionStorage.getItem("lang")
    languageTranslationService.setLang(sessionStorage.getItem("lang"));
  }
}
