import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

// create and export a vuex store 
export default new Vuex.Store({
    state: {
        languages: null
    },

    getters: {},

    mutations: {
        setLanguages(state, languages) {
            state.languages = languages;
        }
    },

    actions: {
        setLanguages(context, languages) {
            context.commit('setLanguages', languages);
        }

    }
})