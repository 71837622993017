import {
  Subject
} from "rxjs";

// create a state in subject state
const langSubject = new Subject();
const loaderSubject = new Subject();
const loaderSidebarSubject = new Subject();
const refreshCollaborationDetails = new Subject();
const pageSubject = new Subject();
const branchSubject = new Subject();
const departmentSubject = new Subject();
const subscribeSubject = new Subject();
const headerSubscribeSubject = new Subject();
const branchorDepartmentSubject = new Subject();

//create and export set and get language from languageTranslationService state
export const languageTranslationService = {
  setLang: (lang) => langSubject.next(lang),
  getLang: () => langSubject.asObservable(),
};

//create and export set and get language from BranchorDepartmentChangeService state
export const BranchorDepartmentChangeService = {
  setBranchorDepartment: (obj) => branchorDepartmentSubject.next(obj),
  getBranchorDepartment: () => branchorDepartmentSubject.asObservable(),
};

//create and export set and get language from branchChangeService state
export const branchChangeService = {
  setBranch: (branch) => branchSubject.next(branch),
  getBranch: () => branchSubject.asObservable(),
};

//create and export set and get language from departmentChangeService state
export const departmentChangeService = {
  setDepartment: (department) => departmentSubject.next(department),
  getDepartment: () => departmentSubject.asObservable(),
};

//create and export set and get language from loaderService state
export const loaderService = {
  setLoader: (showLoader) => loaderSubject.next(showLoader),
  getLoader: () => loaderSubject.asObservable(),
};

//create and export set and get language from loaderServiceSidebar state
export const loaderServiceSidebar = {
  setLoader: (showSidebarLoader) => loaderSidebarSubject.next(showSidebarLoader),
  getLoader: () => loaderSidebarSubject.asObservable(),
};

//create and export set and get language from refreshCollaborationDetail state
export const refreshCollaborationDetail = {
  setRefreshCollaborationDetailById: (id) =>
    refreshCollaborationDetails.next(id),
  getRefreshCollaborationDetail: () =>
    refreshCollaborationDetails.asObservable(),
};

//create and export set and get language from pageChangeService state
export const pageChangeService = {
  setPageData: (data) => pageSubject.next(data),
  getPageData: () => pageSubject.asObservable(),
}

//create and export set and get language from subscribeService state
export const subscribeService = {
  setSubscribe:(value) => subscribeSubject.next(value),
  getSubscribe:() => subscribeSubject.asObservable(),
}

//create and export set and get language from headerSubscribeService state
export const headerSubscribeService = {
  setHeaderSubscribe:(val) => headerSubscribeSubject.next(val),
  getHeaderSubscribe:() => headerSubscribeSubject.asObservable(),
}